import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserGQL } from '@app/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class StatementGenerationService {
  private generatingFilesCountMap = new Map<number, number>();
  private isGeneratingSubject = new BehaviorSubject<boolean>(false);
  isGenerating$ = this.isGeneratingSubject.asObservable();
  userId: number;

  constructor(private userGql: UserGQL) {
    this.initializeUser();
  }

  private initializeUser(): void {
    this.userGql.fetch().subscribe((gqlResponse) => {
      this.userId = gqlResponse.data.myUser.id;
      if (!this.generatingFilesCountMap.has(this.userId)) {
        this.generatingFilesCountMap.set(this.userId, 0);
      }
    });
  }

  startGeneration(): void {
    if (!this.userId) return;
    const currentCount = this.generatingFilesCountMap.get(this.userId) || 0;
    this.generatingFilesCountMap.set(this.userId, currentCount + 1);
    this.updateGeneratingState();
  }

  completeGeneration(): void {
    if (!this.userId) return;
    const currentCount = this.generatingFilesCountMap.get(this.userId) || 0;
    if (currentCount > 0) {
      this.generatingFilesCountMap.set(this.userId, currentCount - 1);
      this.updateGeneratingState();
    }
  }

  private updateGeneratingState(): void {
    if (!this.userId) return;
    const currentCount = this.generatingFilesCountMap.get(this.userId) || 0;
    const isGenerating = currentCount > 0;
    if (this.isGeneratingSubject.value !== isGenerating) {
      this.isGeneratingSubject.next(isGenerating);
    }
  }
}
